import { t } from 'i18next';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { Api } from '../../../../services/config/Api';
import { ApiUtils } from '../../../../utils';
import { DATASET_ACTIONS_KEY } from '../../../commons/DatasetConstants';
import { RUNNER_ACTIONS_KEY } from '../../../commons/RunnerConstants';
import { dispatchSetApplicationErrorMessage } from '../../../dispatchers/app/ApplicationDispatcher';
import { createDataset } from '../../datasets/CreateDataset';

const getUserEmail = (state) => state.auth.userEmail;
const getSelectedDatasetIndex = (state) => state.dataset?.selectedDatasetIndex;
const getSelectedDatasetId = (state) => {
  const index = state.dataset?.selectedDatasetIndex;
  return state.dataset?.list.data[index].id;
};

export function* createDatastoreRunner(action) {
  try {
    const organizationId = action.organizationId;
    const workspaceId = action.workspaceId;
    const userEmail = yield select(getUserEmail);
    const dataset = {
      ...action.dataset,
      tags: ['datastore'],
      sourceType: 'None',
    };
    yield call(createDataset, { dataset, organizationId });
    const datasetIdFromState = yield select(getSelectedDatasetId);
    const source = {
      name: dataset.name,
      location: organizationId,
      path: `${workspaceId}/datastore/${datasetIdFromState}`,
    };
    const updatedDataset = {
      ...dataset,
      source,
      sourceType: 'AzureStorage',
    };
    const datasetIndex = yield select(getSelectedDatasetIndex);
    yield put({
      type: DATASET_ACTIONS_KEY.TRIGGER_SAGA_UPDATE_DATASET,
      organizationId,
      datasetId: datasetIdFromState,
      datasetData: updatedDataset,
      datasetIndex,
    });
    // setting default admin for now until the share button is implemented
    yield call(Api.Datasets.setDatasetDefaultSecurity, organizationId, datasetIdFromState, { role: 'admin' });

    const runner = { ...action.dataset };
    runner.security = { default: 'admin', accessControlList: [{ id: userEmail, role: 'admin' }] };
    runner.runTemplateId = 'datastore_checking';
    runner.parametersValues = [
      {
        parameterId: 'source_dataset_id',
        value: datasetIdFromState,
      },
    ];
    delete runner.sourceType;

    yield call(Api.Runners.createRunner, organizationId, workspaceId, {
      ...runner,
      parametersValues: ApiUtils.formatParametersForApi(runner.parametersValues).parametersValues,
    });

    yield put({
      type: RUNNER_ACTIONS_KEY.TRIGGER_SAGA_UPDATE_DATASTORE_ASSOCIATED_RUNNER,
      organizationId,
      workspaceId,
      datasetId: datasetIdFromState,
    });
  } catch (error) {
    console.error(error);
    yield put(
      dispatchSetApplicationErrorMessage(
        error,
        t('commoncomponents.banner.runnerNotCreated', "Runner hasn't been created")
      )
    );
  }
}

export function* createDatastoreRunnerSaga() {
  yield takeEvery(RUNNER_ACTIONS_KEY.TRIGGER_SAGA_CREATE_DATASTORE_RUNNER, createDatastoreRunner);
}
