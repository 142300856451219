import { put, takeEvery, call, select } from 'redux-saga/effects';
import { Api } from '../../../../services/config/Api';
import { DATASET_ACTIONS_KEY } from '../../../commons/DatasetConstants';
import { RUNNER_ACTIONS_KEY } from '../../../commons/RunnerConstants';
import { dispatchSetApplicationErrorMessage } from '../../../dispatchers/app/ApplicationDispatcher';

const getDataset = (state, datasetId) => state.dataset.list.data.find((dataset) => dataset.id === datasetId);
export function* updateDataStoreAssociatedRunner(action) {
  try {
    const organizationId = action.organizationId;
    const workspaceId = action.workspaceId;
    const { data: runners } = yield call(Api.Runners.listRunners, organizationId, workspaceId, undefined, 10000);
    const associatedRunner = runners.find((runner) => {
      return runner.parametersValues.some((param) => {
        return param.parameterId === 'source_dataset_id' && param.value === action.datasetId;
      });
    });
    // runner state is currently not handled properly, need to fetch last run state instead
    let lastRunState = null;
    let lastRunId = null;
    if (associatedRunner.lastRun) {
      const {
        data: { state, id },
      } = yield call(
        Api.RunnerRuns.getRun,
        organizationId,
        workspaceId,
        associatedRunner.id,
        associatedRunner.lastRun.runnerRunId
      );
      lastRunState = state;
      lastRunId = id;
    }

    const dataset = yield select(getDataset, action.datasetId);
    const { associatedRunner: prevAssociatedRunner } = dataset;
    // updating only if no previous associated runner found
    // or if the state of the run changed in order not to trigger dataset object update
    if (!prevAssociatedRunner || prevAssociatedRunner.state !== lastRunState) {
      if (lastRunState === 'Successful') {
        const {
          data: { tags },
        } = yield call(Api.Datasets.findDatasetById, organizationId, action.datasetId);
        yield put({
          type: DATASET_ACTIONS_KEY.UPDATE_DATASET,
          datasetId: action.datasetId,
          datasetData: { ...dataset, tags },
        });
      }
      yield put({
        type: DATASET_ACTIONS_KEY.UPDATE_DATASTORE_ASSOCIATED_RUNNER,
        datasetId: action.datasetId,
        associatedRunnerDetails: { id: associatedRunner.id, state: lastRunState, runnerRunId: lastRunId },
      });
    }
  } catch (error) {
    console.error(error);
    yield put(
      dispatchSetApplicationErrorMessage(
        error,
        // t('commoncomponents.banner.runnerNotCreated', "Runner hasn't been created")
        "Le runner associé à ce dataset n'a pas été trouvé"
      )
    );
  }
}

export function* updateDataStoreAssociatedRunnerSaga() {
  yield takeEvery(RUNNER_ACTIONS_KEY.TRIGGER_SAGA_UPDATE_DATASTORE_ASSOCIATED_RUNNER, updateDataStoreAssociatedRunner);
}
