import { all, fork } from 'redux-saga/effects';
import { createDatastoreRunnerSaga } from './CreateDataStoreRunner';
import { createRunnerSaga } from './CreateRunner';
import { stopRunnerSaga } from './StopRunner';
import { updateDataStoreAssociatedRunnerSaga } from './UpdateDatastoreAssociatedRunner';

export default function* runnerSaga() {
  yield all([
    fork(createRunnerSaga),
    fork(createDatastoreRunnerSaga),
    fork(updateDataStoreAssociatedRunnerSaga),
    fork(stopRunnerSaga),
  ]);
}
