import { useCallback } from 'react';
import { Api } from '../../../../../../services/config/Api';
import { useCurrentDataset } from '../../../../../../state/hooks/DatasetHooks';
import { useOrganizationId } from '../../../../../../state/hooks/OrganizationHooks';
import { useUpdateDatastoreAssociatedRunner } from '../../../../../../state/hooks/RunnerHooks';
import { useWorkspaceId } from '../../../../../../state/hooks/WorkspaceHooks';

export const useStartChecking = () => {
  const organizationId = useOrganizationId();
  const workspaceId = useWorkspaceId();
  const { associatedRunner, id: currentDatasetId } = useCurrentDataset();
  const updateDataStoreAssociatedRunner = useUpdateDatastoreAssociatedRunner();
  const startRunner = useCallback(async () => {
    Api.Runners.startRun(organizationId, workspaceId, associatedRunner.id)
      .then(() => updateDataStoreAssociatedRunner(currentDatasetId))
      .catch((error) => console.error(error));
  }, [associatedRunner, currentDatasetId, organizationId, workspaceId, updateDataStoreAssociatedRunner]);

  return startRunner;
};
