import React, { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Divider, Backdrop, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useCurrentDataset, useSelectDataset } from '../../state/hooks/DatasetHooks';
import { useDataStoreFileDownload, useDataStoreListAllFiles } from './DataStoreHooks';
import { CreateDataStoreItemButton, DataStoreItemsList, DataStoreItemDetails, DataStoreTablesList } from './components';

export const ValidationContext = createContext(null);
export const TablesBackdropOpenContext = createContext(null);
const DataStore = () => {
  const { t } = useTranslation();
  const dataset = useCurrentDataset();
  const [loading, setLoading] = useState(false);
  const [filesList, setFilesList] = useState([]);
  const [orderedTablesList, setOrderedTablesList] = useState([]);
  const [validation, setValidation] = useState({ status: null, details: [], tables: {} });
  const [isArchiveAvailable, setIsArchiveAvailable] = useState(false);
  const [isReportAvailable, setIsReportAvailable] = useState(false);
  const [storageRoot, setStorageRoot] = useState(null);
  const [tablesBackdropOpen, setTablesBackdropOpen] = useState(false);
  const downloadFile = useDataStoreFileDownload();
  const listAllFiles = useDataStoreListAllFiles();
  const selectDataset = useSelectDataset();
  useEffect(() => {
    if (dataset && !['Unknown', 'Running'].includes(dataset.associatedRunner?.state)) {
      setLoading(true);
      listAllFiles()
        .then((data) => {
          const datasetStorageRoot = `datastore/${dataset.id}/`;
          setStorageRoot(datasetStorageRoot);
          const datasetFilteredFiles = data.data.filter((file) => file.fileName.startsWith(datasetStorageRoot));
          const datasetFilteredFilesWithNames = datasetFilteredFiles.map((fileDetail) => ({
            storagePath: fileDetail.fileName,
            name: fileDetail.fileName.replace(datasetStorageRoot, ''),
          }));
          setFilesList(datasetFilteredFilesWithNames);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, [listAllFiles, dataset]);
  useEffect(() => {
    // mounted is used to not assign validation in case the component is unmounted (ex: switching datastores)
    let mounted = true;
    if (filesList.some((file) => file.name === 'datastore.zip')) {
      setIsArchiveAvailable(true);
    }
    if (filesList.some((file) => file.name === 'validation_report.txt')) {
      setIsReportAvailable(true);
    }
    const validationFile = filesList.find((file) => file.name === '_validation.json');
    const tablesList = filesList.filter((file) => file.name.endsWith('.csv'));
    if (!validationFile) {
      setOrderedTablesList(tablesList.sort((a, b) => a.name.localeCompare(b.name)));
      setLoading(false);
      return;
    }
    downloadFile(validationFile.storagePath)
      .then((validationData) => {
        if (mounted) {
          setValidation(validationData.data);
          const validationTables = Object.keys(validationData.data.tables);
          const validationOrder = validationTables.reduce((acc, name, index) => {
            acc[name] = index;
            return acc;
          }, {});

          const sortedTables = [];
          const notInValidationTable = [];
          tablesList.forEach((table) => {
            if (Object.prototype.hasOwnProperty.call(validationOrder, table.name)) {
              sortedTables.push(table);
            } else {
              notInValidationTable.push(table);
            }
          });
          notInValidationTable.sort((a, b) => a.name.localeCompare(b.name));
          const allTablesSorted = [...sortedTables, ...notInValidationTable];
          setOrderedTablesList(allTablesSorted);
        }
      })
      .catch((error) => {
        console.error('Error downloading validation file:', error);
      })
      .finally(() => setLoading(false));
    return () => {
      mounted = false;
      setValidation({ status: '', details: [], tables: {} });
      setIsArchiveAvailable(false);
      setIsReportAvailable(false);
    };
  }, [filesList, downloadFile]);
  useEffect(() => {
    setTablesBackdropOpen(['Unknown', 'Running'].includes(dataset?.associatedRunner?.state));
  }, [dataset]);

  useEffect(() => {
    return () => {
      selectDataset({ id: null });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ValidationContext.Provider value={{ validation, storageRoot, isArchiveAvailable, isReportAvailable }}>
      <Grid container direction="column" sx={{ height: '100%', p: 2, overflow: 'auto', display: 'flex' }} wrap="nowrap">
        <Grid item id="top">
          <Grid container>
            <Grid item xs={3}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1em',
                  p: 2,
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    gap: '1em',
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <DataStoreItemsList />
                  </Box>
                  <CreateDataStoreItemButton />
                </Box>
                {dataset !== null && dataset?.tags?.includes('datastore') && (
                  <Box>
                    <Typography sx={{ fontSize: 14 }}>
                      <strong>{t('commoncomponents.datastore.details.metadata.owner', 'Owner:')} </strong>
                      {dataset.ownerName}
                    </Typography>
                    <Typography component="span" sx={{ fontSize: 14 }}>
                      <strong>
                        {t('commoncomponents.datastore.details.metadata.creationDate', 'Creation date:')}{' '}
                      </strong>
                      {new Date(dataset.creationDate).toLocaleString(undefined, {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={9}>
              {dataset === null || !dataset?.tags?.includes('datastore') ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                  <Typography variant="h6">
                    {t('commoncomponents.datastore.details.placeholder.text', 'Please select a datastore to work with')}
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ width: '100%', height: '100%' }}>
                  <DataStoreItemDetails startDisabled={orderedTablesList.length === 0} />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ my: 1 }} />
        <Grid item id="files-list" sx={{ flexGrow: 1, overflowY: 'auto', position: 'relative' }}>
          <Backdrop
            open={tablesBackdropOpen}
            sx={{ position: 'absolute', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          {dataset === null || !dataset?.tags?.includes('datastore') ? (
            <div></div>
          ) : (
            <Box sx={{ height: '100%' }}>
              <TablesBackdropOpenContext.Provider value={{ setTablesBackdropOpen }}>
                <DataStoreTablesList storageRoot={storageRoot} loading={loading} tablesList={orderedTablesList} />
              </TablesBackdropOpenContext.Provider>
            </Box>
          )}
        </Grid>
      </Grid>
    </ValidationContext.Provider>
  );
};

export default DataStore;
