import { useCallback, useMemo } from 'react';
import { Api } from '../../../../../../services/config/Api';
import RunnerRunService from '../../../../../../services/runnerRun/RunnerRunService';
import { useCurrentDataset, useDeleteDataset } from '../../../../../../state/hooks/DatasetHooks';
import { useOrganizationId } from '../../../../../../state/hooks/OrganizationHooks';
import { useWorkspaceId } from '../../../../../../state/hooks/WorkspaceHooks';
import { useDataStoreListAllFiles } from '../../../../DataStoreHooks';

export const useDownloadRunLogs = () => {
  const organizationId = useOrganizationId();
  const workspaceId = useWorkspaceId();
  return useCallback(
    (runnerId, runnerRunId) => RunnerRunService.downloadLogsFile(organizationId, workspaceId, runnerId, runnerRunId),
    [organizationId, workspaceId]
  );
};

export const useDeleteDataStore = () => {
  const organizationId = useOrganizationId();
  const workspaceId = useWorkspaceId();
  const dataset = useCurrentDataset();
  const listAllFiles = useDataStoreListAllFiles();
  const deleteDataset = useDeleteDataset();
  const datasetStorageRoot = useMemo(() => `datastore/${dataset.id}/`, [dataset]);
  return useCallback(() => {
    listAllFiles().then((data) => {
      data.data
        .filter((file) => file.fileName.startsWith(datasetStorageRoot))
        .forEach((file) => Api.Workspaces.deleteWorkspaceFile(organizationId, workspaceId, file.fileName));
    });
    if (dataset.associatedRunner) {
      Api.Runners.deleteRunner(organizationId, workspaceId, dataset.associatedRunner.id);
    }
    deleteDataset(dataset.id);
  }, [listAllFiles, datasetStorageRoot, organizationId, workspaceId, dataset, deleteDataset]);
};
