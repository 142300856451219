import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Error, ExpandMore } from '@mui/icons-material';
import { Box, Card, List, ListItem, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useCurrentDataset } from '../../../../state/hooks/DatasetHooks';
import { useUpdateDatastoreAssociatedRunner } from '../../../../state/hooks/RunnerHooks';
import { ValidationContext } from '../../DataStore';
import { StatusChip } from '../StatusChip';
import { DataStoreOptions, StartCheckingButton } from './components';

const useStyles = makeStyles((theme) => ({
  datasetHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

export const DataStoreItemDetails = ({ startDisabled }) => {
  const { validation } = useContext(ValidationContext);
  const classes = useStyles();
  const dataset = useCurrentDataset();
  const [isRunning, setIsRunning] = useState(false);
  const updateDataStoreAssociatedRunner = useUpdateDatastoreAssociatedRunner();

  const updateRunningStatus = (state) => {
    try {
      setIsRunning(['Unknown', 'Running'].includes(state));
    } catch (error) {
      console.error('Error fetching associated runner:', error);
    }
  };

  useEffect(() => {
    const { state } = dataset.associatedRunner || { state: null };

    updateRunningStatus(state);
    if (['Unknown', 'Running'].includes(state)) {
      const intervalId = setInterval(() => {
        const { state: newState } = dataset.associatedRunner || { state: null };
        updateRunningStatus(newState);
        updateDataStoreAssociatedRunner(dataset.id);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [dataset, updateDataStoreAssociatedRunner]);
  return (
    <Box sx={{ height: '100%' }}>
      <Card className={classes.datasetHeader} sx={{ p: 2, display: 'flex' }}>
        <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4">{dataset?.name}</Typography>
            <StatusChip size="medium" status={validation.status || 'draft'} sx={{ ml: 2 }} />
          </Box>
          <Typography sx={{ fontStyle: 'italic', pt: 2 }}>{dataset.description}</Typography>
        </Box>
        {/* <Typography fontSize={10} fontStyle={'italic'}>
            debug: runner id = {dataset.associatedRunner?.id} | lastRunId = {dataset.associatedRunner?.runnerRunId} |
            datastore prefix = datastore/{dataset.id}
          </Typography> */}
        <Box sx={{ ml: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <DataStoreOptions />
          <StartCheckingButton running={isRunning} disabled={startDisabled} />
        </Box>
      </Card>
      {validation.details.length > 0 && (
        <Accordion>
          <AccordionSummary sx={{ color: 'error' }} expandIcon={<ExpandMore />}>
            <Error color="error" />
            <Typography color="error">{validation.details.length} erreur(s) de validation</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List sx={{ listStyleType: 'disc', listStylePosition: 'inside' }}>
              {validation.details.map((detail) => (
                <ListItem key={detail} sx={{ display: 'list-item' }}>
                  {detail}
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};

DataStoreItemDetails.propTypes = {
  startDisabled: PropTypes.bool,
};
