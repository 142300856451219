import { useCallback, useMemo } from 'react';
import { useWorkspaceMainDatasets } from '../../../../hooks/WorkspaceDatasetsHooks';
import { ACL_PERMISSIONS } from '../../../../services/config/accessControl';
import { useCurrentDataset, useSelectDataset } from '../../../../state/hooks/DatasetHooks';
import { useOrganizationData } from '../../../../state/hooks/OrganizationHooks';
import { useUpdateDatastoreAssociatedRunner } from '../../../../state/hooks/RunnerHooks';

export const useDataStoreList = () => {
  const userPermissionsInCurrentOrganization = useOrganizationData()?.security?.currentUserPermissions ?? [];
  const datasets = useWorkspaceMainDatasets();
  const selectDataset = useSelectDataset();
  const updateDataStoreAssociatedRunner = useUpdateDatastoreAssociatedRunner();
  const selectDatasetAndRunner = useCallback(
    (dataset) => {
      selectDataset(dataset);
      updateDataStoreAssociatedRunner(dataset.id);
    },
    [selectDataset, updateDataStoreAssociatedRunner]
  );
  const currentDataset = useCurrentDataset();

  const visibleDataStoreItems = useMemo(
    () =>
      datasets.filter(
        (dataset) =>
          dataset?.security?.currentUserPermissions?.includes(ACL_PERMISSIONS.DATASET.READ) &&
          dataset?.tags?.includes('datastore')
      ),
    [datasets]
  );

  return {
    userPermissionsInCurrentOrganization,
    dataStoreItems: visibleDataStoreItems,
    currentDataset,
    selectDatasetAndRunner,
  };
};
